import { useState } from 'react';
import { useLanguageStore, useInvoiceStore, useAlertStore } from '../stores';
import { Button, BackButton } from '../components';
import { TextField } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { Animated } from 'react-animated-css';
import paymentCompleteCheck from '../assets/paymentCompleteCheck.svg';

export default function Report() {
	const { t } = useLanguageStore();
	const { alert } = useAlertStore();
	const { report } = useInvoiceStore();
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();
	const [submitting, setSubmitting] = useState(false);
	const [sent, setSent] = useState(false);

	const onSubmit = async ({ feedback }) => {
		try {
			setSubmitting(true);
			await report({ feedback });
			setSubmitting(false);
			setSent(true);
		} catch (e) {
			alert(`Oops!`, e.message);
			setSubmitting(false);
		}
	};

	let index = 0;
	const fadeItem = element => (
		<Animated animationIn="fadeIn" animationInDelay={index++ * 50} animationInDuration={800}>
			{element}
		</Animated>
	);

	return (
		<div className="page-container top">
			<div className="page">
				<BackButton />

				{fadeItem(<h2 style={{ marginBottom: 40 }}>{t('Report This Invoice')}</h2>)}
				{fadeItem(<div className="shadow-divider" />)}
				<form style={{ alignSelf: 'stretch' }} onSubmit={handleSubmit(onSubmit)}>
					{fadeItem(
						<Controller
							name="feedback"
							render={({ field }) => (
								<TextField
									{...field}
									id="feedback"
									label={t('Feedback')}
									variant="filled"
									sx={{ my: 1, width: '100%' }}
									helperText={errors?.feedback?.message}
									error={!!errors?.feedback}
									disabled={sent}
									autoFocus
								/>
							)}
							control={control}
							defaultValue=""
							rules={{
								required: t('IsRequired', { field: t('Feedback') }),
							}}
						/>,
					)}
				</form>

				{!sent &&
					fadeItem(
						<Button
							onClick={handleSubmit(onSubmit)}
							loading={submitting}
							type="submit"
							style={{ width: 240, marginTop: 20 }}>
							{t('Submit')}
						</Button>,
					)}
				{sent &&
					fadeItem(
						<>
							<img
								alt="Checkmark"
								src={paymentCompleteCheck}
								style={{ marginTop: 24, marginBottom: 0 }}
							/>
							<h3 className="success">{t('Feedback sent.')}</h3>
						</>,
					)}
			</div>
		</div>
	);
}

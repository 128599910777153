import create from 'zustand';
import API from '../util/API';

const useInvoiceStore = create((set, get) => ({
	token: null,
	setToken: async token => {
		const { token: oldToken, loadSession } = get();
		if (token !== oldToken) {
			set({ token, hasLoadedSession: false });
			await loadSession();
		}
	},
	hasLoadedSession: false,
	merchantId: null,
	merchantName: null,
	merchantAvatar: null,
	purchaseId: null,
	status: null,
	email: null,
	mobile: null,
	title: null,
	total: null,
	items: null,
	date: null,
	returnUrl: null,
	ach: null,
	paymentDetails: null,
	invoicePdfUrl: null,
	receiptPdfUrl: null,
	error: false,
	tipConfig: null,
	isEmbedded: () => window.location !== window.parent.location,
	canReturnToEnterprise: () => {
		const { returnUrl, isEmbedded } = get();
		if (window.ReactNativeWebView) {
			return true;
		} else if (window.connectMessageHandler && window.connectMessageHandler.postMessage) {
			return true;
		} else if (
			window.webkit &&
			window.webkit.messageHandlers.connectMessageHandler &&
			window.webkit.messageHandlers.connectMessageHandler.postMessage
		) {
			return true;
		} else if (isEmbedded()) {
			return true;
		} else {
			return !!returnUrl;
		}
	},
	returnToEnterprise: () => {
		const { returnUrl, isEmbedded } = get();
		const message = 'connect_exit';
		if (window.ReactNativeWebView) {
			window.ReactNativeWebView.postMessage(message);
		} else if (window.connectMessageHandler && window.connectMessageHandler.postMessage) {
			window.connectMessageHandler.postMessage(message);
		} else if (
			window.webkit &&
			window.webkit.messageHandlers.connectMessageHandler &&
			window.webkit.messageHandlers.connectMessageHandler.postMessage
		) {
			window.webkit.messageHandlers.connectMessageHandler.postMessage(message);
		} else if (isEmbedded()) {
			window.parent.postMessage(message, '*');
		} else if (returnUrl) {
			window.location.assign(returnUrl);
		}
	},
	loadSession: async () => {
		const { token } = get();
		if (!token) return;

		let session;
		try {
			session = await API.get(`/banking/checkout/invoice/${token}`);
		} catch (e) {
			set({ error: true });
			return;
		}

		const {
			merchant: { name: merchantName, avatar: merchantAvatar, merchantId },
			purchase: {
				id: purchaseId,
				status,
				paymentDetails,
				email,
				mobile,
				title,
				total,
				items,
				date,
				invoicePdfUrl,
				receiptPdfUrl,
			},
			ach,
		} = session;

		set({
			hasLoadedSession: true,
			merchantId,
			merchantName,
			merchantAvatar,
			purchaseId,
			status,
			paymentDetails,
			email,
			mobile,
			title,
			total,
			items,
			date,
			ach,
			invoicePdfUrl,
			receiptPdfUrl,
		});
	},
	payInvoice: async ({ purchaseId, payload }) => {
		return await API.post(`/banking/invoice/${purchaseId}/pay`, payload);
	},
	report: async ({ feedback }) => {
		const { token } = get();
		return await API.post(`/banking/checkout/invoice/${token}/report`, { feedback });
	},
}));

useInvoiceStore.getState().loadSession();

export default useInvoiceStore;

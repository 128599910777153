import React from 'react';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

export default function Button({
	small = false,
	large = false,
	centered = true,
	theme = 'primary',
	style,
	type,
	loading = false,
	enabled = true,
	children,
	to,
	target = '_blank',
	onClick,
	icon,
}) {
	let disabled = !enabled;
	const spinner = (
		<CircularProgress
			color="inherit"
			style={small ? { width: 16, height: 16, marginTop: 7 } : { width: 24, height: 24, marginTop: 13 }}
		/>
	);
	const className = (centered ? 'centered ' : '') + (small ? 'small ' : '') + (large ? 'large ' : '') + theme;
	const containerStyle = centered
		? { alignSelf: 'stretch', display: 'flex', flexDirection: 'column', cursor: enabled ? 'pointer' : 'default' }
		: { cursor: enabled ? 'pointer' : 'default' };

	if (to) {
		if (typeof to === 'string' && (to.indexOf('http://') > -1 || to.indexOf('https://') > -1)) {
			return (
				<a className="button" href={to} target={target} style={containerStyle}>
					<button className={className} disabled={disabled || loading} style={style} type={type}>
						{icon && <img src={icon} alt={children.toString()} />}
						{loading ? spinner : children}
					</button>
				</a>
			);
		} else {
			return (
				<Link className="button" to={to} style={containerStyle}>
					<button className={className} disabled={disabled || loading} style={style} type={type}>
						{icon && <img src={icon} alt={children.toString()} />}
						{loading ? spinner : children}
					</button>
				</Link>
			);
		}
	} else {
		return (
			<button
				className={className}
				disabled={disabled || loading}
				onClick={onClick}
				style={{ ...style, cursor: enabled ? 'pointer' : 'default' }}
				type={type}>
				{icon && <img src={icon} alt={children.toString()} />}
				{loading ? spinner : children}
			</button>
		);
	}
}

import { useState, useEffect, useRef } from 'react';
import { useLanguageStore, useInvoiceStore, useAlertStore } from '../stores';
import moment from 'moment';
import { Button, SandboxChip, SecurityChip, BackButton } from '../components';
import { fromCents } from '../util/string';
import { Animated } from 'react-animated-css';
import paymentCompleteCheck from '../assets/paymentCompleteCheck.svg';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { FINIX_ENV, FINIX_APPLICATION_ID } from '../util/config';

export default function Invoice() {
	const { t } = useLanguageStore();
	const { alert } = useAlertStore();
	const {
		purchaseId,
		title,
		date,
		status,
		paymentDetails,
		merchantAvatar,
		merchantId,
		merchantName,
		items,
		total,
		ach,
		receiptPdfUrl,
		payInvoice,
		loadSession,
		returnToEnterprise,
		canReturnToEnterprise,
		isEmbedded,
	} = useInvoiceStore();
	const showingBanner = false;
	const [submitting, setSubmitting] = useState(false);
	const [finixForm, setFinixForm] = useState(null);
	const [formHasErrors, setFormHasErrors] = useState(false);
	const [idempotencyId, setIdempotencyId] = useState(uuidv4());
	const [FinixAuth, setFinixAuth] = useState(null);
	const numberHasBeenFocused = useRef(false);
	const expirationHasBeenFocused = useRef(false);
	const cvcHasBeenFocused = useRef(false);
	const zipHasBeenFocused = useRef(false);
	const numberHasBeenBlurred = useRef(false);
	const expirationHasBeenBlurred = useRef(false);
	const cvcHasBeenBlurred = useRef(false);
	const zipHasBeenBlurred = useRef(false);

	useEffect(() => {
		const finixAuth = window.Finix.Auth(FINIX_ENV, merchantId);
		setFinixAuth(finixAuth);
	}, []);

	const onSubmitPress = e => {
		e.preventDefault();
		setSubmitting(true);

		if (formHasErrors) {
			alert(`Oops!`, `Please double-check that all fields are completed correctly.`);
			setSubmitting(false);
			return;
		}

		finixForm.submit(FINIX_ENV, FINIX_APPLICATION_ID, async function (err, res) {
			// get token ID from response
			const tokenData = res.data || {};
			const paymentToken = tokenData.id;
			try {
				await payInvoice({
					purchaseId,
					payload: { paymentToken, fraudSessionId: FinixAuth.getSessionKey(), idempotencyId },
				});

				await loadSession();
				setSubmitting(false);
			} catch (err) {
				alert(`There was an error.`, `${err.message}`);
				setSubmitting(false);
				setIdempotencyId(uuidv4());
			}
		});
	};

	const showErrorMessage = (id, value) => {
		document.getElementById(id).style.display = value ? 'inline' : 'none';
	};

	const onUpdate = (state, binInformation, formHasErrors) => {
		if (state) {
			// the finix validation errors aggressively show up when the user focuses on the field, so we are showing/hiding on our own
			numberHasBeenFocused.current = state.number.isFocused || numberHasBeenFocused.current;
			expirationHasBeenFocused.current = state.expiration_date.isFocused || expirationHasBeenFocused.current;
			cvcHasBeenFocused.current = state.security_code.isFocused || cvcHasBeenFocused.current;
			zipHasBeenFocused.current = state['address.postal_code'].isFocused || zipHasBeenFocused.current;
			numberHasBeenBlurred.current =
				(numberHasBeenFocused.current && !state.number.isFocused) || numberHasBeenBlurred.current;
			expirationHasBeenBlurred.current =
				(expirationHasBeenFocused.current && !state.expiration_date.isFocused) ||
				expirationHasBeenBlurred.current;
			cvcHasBeenBlurred.current =
				(cvcHasBeenFocused.current && !state.security_code.isFocused) || cvcHasBeenBlurred.current;
			zipHasBeenBlurred.current =
				(zipHasBeenFocused.current && !state['address.postal_code'].isFocused) || zipHasBeenBlurred.current;

			showErrorMessage('number_validation', numberHasBeenBlurred.current);
			showErrorMessage('expiration_date_validation', expirationHasBeenBlurred.current);
			showErrorMessage('security_code_validation', cvcHasBeenBlurred.current);
			showErrorMessage('address_postal_code_validation', zipHasBeenBlurred.current);
		}

		setFormHasErrors(formHasErrors);
	};

	useEffect(() => {
		setFinixForm(
			window.Finix.CardTokenForm('finix-form', {
				onUpdate,
				showPlaceholders: true,
				showAddress: true,
				hideFields: [
					'name',
					'address_line1',
					'address_line2',
					'address_city',
					'address_region',
					'address_state',
					'address_country',
				],
				placeholders: {
					number: 'Card Number',
					expiration_date: 'MM / YY',
					security_code: 'CVC',
					address_postal_code: 'ZIP Code',
				},
				labels: {
					number: 'Credit / Debit Card',
					expiration_date: 'Expiration',
					security_code: 'Security Code',
					address_postal_code: 'ZIP Code',
				},
				styles: {
					// default styling for all fields
					default: {
						color: '#000',
						backgroundColor: '#F9F9F9',
						borderBottom: '1px solid #DEE3EB',
						borderRadius: '0px',
						padding: '8px 16px',
						fontFamily: 'Helvetica',
						fontSize: '16px',
						height: '56px',
						placeholder: '#8DA4C4',
					},
					success: {
						// specific styling if the field is valid
					},
					error: {
						color: '#d7625e', // specific styling if the field has errors
						borderBottom: '1px solid #d7625e',
					},
				},
				hideErrorMessages: false,
				errorMessages: {
					number: 'Invalid Card Number',
					expiration_date: 'Invalid Expiration',
					security_code: 'Invalid CVC',
					address_postal_code: 'Invalid ZIP code',
				},
			}),
		);
	}, [setFinixForm, purchaseId, merchantId]);

	let index = 0;
	const fadeItem = element => (
		<Animated key={index} animationIn="fadeIn" animationInDelay={index++ * 50} animationInDuration={800}>
			{element}
		</Animated>
	);

	return (
		<div className="page-container top">
			<div className="page">
				{canReturnToEnterprise() && <BackButton onClick={returnToEnterprise} close={isEmbedded()} />}
				<Animated
					animationIn="slideInRight"
					animationInDelay={1000}
					animationInDuration={800}
					className={`right-nav-button ${showingBanner ? 'showing-banner' : ''}`}>
					<SandboxChip />
					<SecurityChip />
					{/* <Link to={`/en/${token}`}>EN</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
					<Link to={`/es/${token}`}>ES</Link> */}
				</Animated>
				{fadeItem(
					<div className="logo-circle-holder">
						<div className="logo-circle">
							<img src={merchantAvatar} alt={merchantName} />
						</div>
					</div>,
				)}
				{fadeItem(<h2>{merchantName}</h2>)}
				{fadeItem(<p className="date">{title + ' - ' + moment(date).format('MMM D, YYYY')}</p>)}

				<div className="item-list">
					{items.map((item, index) =>
						fadeItem(
							<div className="item">
								<div className="left">
									<span className="description">{item.description}</span>
									<span className="meta">{item.meta}</span>
								</div>
								<span className={item.amount < 0 ? 'amount negative' : 'amount'}>
									{fromCents(item.amount)}
								</span>
							</div>,
						),
					)}
					{fadeItem(
						<div className="total item">
							<div className="left">
								<span className="description">{t('Total')}</span>
							</div>
							<span className="amount">{fromCents(total)}</span>
						</div>,
					)}
				</div>
				{fadeItem(<div className="shadow-divider" />)}
				{status === 'canceled' && fadeItem(<h3 style={{ marginTop: 24 }}>Canceled by {merchantName}.</h3>)}
				{status !== 'created' && status !== 'canceled' && (
					<>
						{fadeItem(
							<>
								<img
									alt="Checkmark"
									src={paymentCompleteCheck}
									style={{ marginTop: 24, marginBottom: 0 }}
								/>
								<h3 className="success">Thank you!</h3>
							</>,
						)}
						{fadeItem(<p className="payment-details">{paymentDetails}</p>)}

						{fadeItem(
							<Button small to={receiptPdfUrl} theme="subtle" style={{ marginTop: 30, marginBottom: 30 }}>
								Download Receipt
							</Button>,
						)}
						{fadeItem(
							<div className="footer-section" style={{ marginTop: 20 }}>
								<a
									href="https://legal.manifestfinancial.com/payor-invoice-terms"
									target="_blank"
									rel="noreferrer"
									style={{ textDecoration: 'underline' }}>
									Terms
								</a>
								&nbsp;&nbsp;&nbsp;
								<Link to={`report`} style={{ textDecoration: 'underline' }}>
									Report this invoice.
								</Link>
							</div>,
						)}
					</>
				)}
				{status === 'created' && (
					<form onSubmit={onSubmitPress} style={{ alignSelf: 'stretch' }}>
						<div id="finix-form" />
						{fadeItem(
							<Button
								loading={submitting}
								type="submit"
								style={{ width: 240, marginTop: 30, marginBottom: 30 }}>
								{t('Pay', { amount: fromCents(total) })}
							</Button>,
						)}
						{ach &&
							false &&
							fadeItem(
								<div className="footer-section" style={{ marginTop: 40 }}>
									<strong>{t('PreferAch', { amount: fromCents(total) })}</strong>
									<br />
									{ach.bankName}
									<br />
									{t('Routing')}: {ach.routingNumber}
									<br />
									{t('Account')}: {ach.accountNumber}
								</div>,
							)}
						{fadeItem(
							<div className="footer-section" style={{ marginTop: 20 }}>
								<a
									href="https://legal.manifestfinancial.com/payor-invoice-terms"
									target="_blank"
									rel="noreferrer"
									style={{ textDecoration: 'underline' }}>
									Terms
								</a>
								&nbsp;&nbsp;&nbsp;
								<Link to={`report`} style={{ textDecoration: 'underline' }}>
									Report this invoice.
								</Link>
							</div>,
						)}
					</form>
				)}
			</div>
		</div>
	);
}

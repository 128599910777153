import './App.scss';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useLanguageStore, useInvoiceStore } from './stores';
import { Button } from './components';
import Loader from 'react-loader-spinner';

export default function App() {
	const { language, token } = useParams();
	const { setLanguage, t } = useLanguageStore();
	const { returnToEnterprise, canReturnToEnterprise, error, merchantName, setToken, hasLoadedSession } =
		useInvoiceStore();

	useEffect(() => {
		setLanguage(language);
	}, [language, setLanguage]);

	useEffect(() => {
		setToken(token);
	}, [token, setToken]);

	if (error) {
		return (
			<div className="page-container centered">
				<div className="page">
					<h1>{t('Oops!')}</h1>
					<p>{t('Your link looks broken. Please try again.')}</p>
					{canReturnToEnterprise() && (
						<Button
							small
							theme="tertiary"
							onClick={returnToEnterprise}
							style={{ width: 240, marginTop: 60 }}>
							{t('BackTo', { merchantName })}
						</Button>
					)}
				</div>
			</div>
		);
	} else if (hasLoadedSession) {
		return <Outlet />;
	} else {
		return (
			<div className="page-container top">
				<div className="page">
					<Loader type="Oval" color="#dee3eb" height={80} width={80} style={{ alignSelf: 'center' }} />
				</div>
			</div>
		);
	}
}

import './index.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { render } from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { datadogRum } from '@datadog/browser-rum';

import App from './App';
import Wrapper from './Wrapper';
import NotFound from './routes/notfound';
import Invoice from './routes/checkout';
import InvoiceReport from './routes/report';

if (process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
	datadogRum.init({
		applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
		clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
		// `site` refers to the Datadog site parameter of your organization
		// see https://docs.datadoghq.com/getting_started/site/
		site: process.env.REACT_APP_DATADOG_SITE,
		service: process.env.REACT_APP_DATADOG_SERVICE_NAME,
		env: process.env.REACT_APP_DATADOG_ENVIRONMENT,
		// Specify a version number to identify the deployed version of your application in Datadog
		// version: '1.0.0',
		sessionSampleRate: 100,
		sessionReplaySampleRate: 20,
		trackUserInteractions: true,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: 'mask-user-input',
	});
}

const theme = createTheme({
	palette: {
		primary: {
			main: '#657C9D',
		},
		secondary: {
			main: '#8DA4C4',
		},
		error: {
			main: '#fe6c6c',
		},
		warning: {
			main: '#FFAC50',
		},
		success: {
			main: '#7AB53F',
		},
		info: {
			main: '#02B8D8',
		},
	},
});

const rootElement = document.getElementById('root');
render(
	<ThemeProvider theme={theme}>
		<BrowserRouter>
			<Routes>
				<Route path="*" element={<NotFound />} />
				<Route element={<Wrapper />}>
					<Route path={'/:language/invoice/:token'} element={<App />}>
						<Route index element={<Invoice />} />
						<Route path={'report'} element={<InvoiceReport />} />
					</Route>
				</Route>
			</Routes>
		</BrowserRouter>
	</ThemeProvider>,
	rootElement,
);

import backButton from '../assets/backButton.svg';
import closeButton from '../assets/closeButton.svg';
import { useNavigate } from 'react-router-dom';

export default function BackButton({ close = false, className, onClick: externalOnClick, style }) {
	const navigate = useNavigate();

	const onClick = () => {
		if (externalOnClick) {
			externalOnClick();
		} else {
			navigate(-1);
		}
	};

	return (
		<div className={`back-button ${className}`} style={style} onClick={onClick}>
			<img src={close ? closeButton : backButton} alt="Go back" />
		</div>
	);
}

import { useLanguageStore } from '../stores';
import parsePhoneNumber from 'libphonenumber-js';

export const numberFormat = function (number, decimals) {
	if (number === null || number === undefined) return '---';

	let dec_point = '.';
	let thousands_sep = ',';
	if (useLanguageStore.getState().language === 'es') {
		dec_point = ',';
		thousands_sep = '.';
	}

	var parts = number.toFixed(decimals).split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

	return parts.join(dec_point);
};

export const fromCents = (num, showPlusWhenPositive = false) => {
	if (num === null || num === undefined) return '---';
	if (num > 0) {
		return `${showPlusWhenPositive ? '+' : ''}$${numberFormat(num / 100, 2)}`;
	} else if (num < 0) {
		return `-$${numberFormat(Math.abs(num) / 100, 2)}`;
	} else {
		return useLanguageStore.getState().language === 'es' ? '$0,00' : '$0.00';
	}
};

export const validatePhone = phoneNumber => {
	try {
		const parsedMobileNumber = parsePhoneNumber(phoneNumber);
		return parsedMobileNumber.isPossible();
	} catch (e) {
		return false;
	}
};

export const formatPhoneNational = phoneNumber => {
	try {
		const parsedMobileNumber = parsePhoneNumber(phoneNumber);
		let nationalNumber = parsedMobileNumber.format('NATIONAL');
		return nationalNumber.replace(/\(|\)/g, '').trim().replace(' ', '-');
	} catch (e) {
		console.log(e);
		return 'Invalid Phone Number';
	}
};

export const validateEmail = email => {
	return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(email);
};

import { useMemo } from 'react';
import lockIcon from '../assets/lockIcon.svg';
import { useDocumentStore, useLanguageStore } from '../stores';

export default function BackButton() {
	const { loadMarkdown } = useDocumentStore();
	const { t, language } = useLanguageStore();

	const body = useMemo(() => {
		if (language === 'es') {
			return `# Nerve está protegido por la seguridad estándar de la industria.
## Encriptación SSL
Toda la comunicación entre usted y Nerve está encriptada utilizando una conexión de capa de conexión segura (SSL) estándar de la industria.
## Seguridad de los datos
Nerve utiliza procesos seguros para proteger su información mientras está en tránsito y en reposo que ayudan a prevenir el uso no autorizado.
## Seguridad financiera
Sus fondos están asegurados por la FDIC hasta 250.000 dólares por cuenta a través de Piermont Bank, miembro de la FDIC.`;
		} else {
			return `# Nerve is secured by industry standard security.
## SSL Encryption
All communication between you and Nerve is encrypted using an industry-standard secure sockets layer (SSL) connection.
## Data Security
Nerve uses secure processes to protect your information while in transit and at rest that help prevent unauthorized use.
## Financial Security
Your funds are FDIC insured up to $250,000 per account through Piermont Bank, Member FDIC.`;
		}
	}, [language]);

	const onClick = () => {
		loadMarkdown(body);
	};

	return (
		<div className={`security-chip`} onClick={onClick}>
			<img src={lockIcon} alt="Security Icon" />
			<span>{t('SSL Encrypted')}</span>
		</div>
	);
}

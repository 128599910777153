import _ from 'lodash';
import axios from 'axios';
import { useAlertStore } from '../stores'; //useDevStore,
export default class API {
	static headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	};

	static baseUrl = () => {
		return process.env.REACT_APP_API_BASE_URL;
	};

	static get = async (path, overrideToken) => await API.jsonRequest('get', path, null, overrideToken);
	static del = async (path, overrideToken) => await API.jsonRequest('delete', path, null, overrideToken);
	static post = async (path, data, overrideToken) => await API.jsonRequest('post', path, data, overrideToken);
	static put = async (path, data, overrideToken) => await API.jsonRequest('put', path, data, overrideToken);
	static patch = async (path, data, overrideToken) => await API.jsonRequest('patch', path, data, overrideToken);

	static multipartPost = async (path, data, onUploadProgress) => {
		let method = 'post';
		let url = API.baseUrl() + path;
		let headers = {
			...API.headers,
			'Content-Type': 'multipart/form-data',
		};

		let config = { method, headers, data, url, onUploadProgress };
		// if (__DEV__) {
		// 	console.log('multipartPost: ' + url + ' ' + (data ? config.data : ''));
		// 	console.log(data);
		// }

		return await API.makeRequest(config);
	};

	static jsonRequest = async (method, path, data, overrideToken) => {
		let url = API.baseUrl() + path;
		let headers = { ...API.headers };

		if (overrideToken) headers.token = overrideToken;
		let config = { method, headers, url };
		if (data) config.data = JSON.stringify(data);
		// if (__DEV__) {
		// 	console.log(method + ': ' + url + ' ' + (data ? config.data : ''));
		// }

		return await API.makeRequest(config);
	};

	static makeRequest = async config => {
		try {
			return await axios(config).then(response => response.data);
		} catch (e) {
			// if (__DEV__) {
			// 	console.log(config.url + ' ' + e);
			// }
			if (e.response.status === 502) {
				throw new Error(`Can't connect to Manifest servers!`);
			} else if (e.response && e.response.status) {
				let status = e.response.status;
				//inactive session
				if (status === 401) {
					// console.log(JSON.stringify(e.response, null, 2));
					useAlertStore.getState().dismissAllAndAlert('Your session has expired.', `Please start over.`);
					return;
				}
				let message = _.get(e.response.data, ['message'], 'An unknown error has occurred.');
				throw new Error(message);
			} else {
				throw new Error(`Can't connect to Nerve servers!`);
			}
		}
	};
}
